import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-numbers-to-text',
  templateUrl: './numbers-to-text.component.html',
  styleUrls: ['./numbers-to-text.component.scss'],
})
export class NumbersToTextComponent
  implements OnInit, AfterContentInit, AfterViewInit {
  @ViewChild('wrapper')
  public slideElement: ElementRef<HTMLElement>;

  @Input() grow: boolean = true;

  @Input()
  direction: 'left' | 'right' = 'left';

  @Input()
  initialDelay: number = 500;

  @Input()
  growInterval: number = 50;

  @Input()
  convertInterval: number = 100;

  private targetText: string;

  /** The current value as a list of strings to easily perform manipulation. */
  private currentList: Array<string> = [];

  ngOnInit() {}

  ngAfterContentInit() {}

  async ngAfterViewInit() {
    this.targetText = this.slideElement.nativeElement.innerText;
    this.currentList = Array<string>(this.targetText.length).fill('');
    this.updateText(this.currentList.join(''));

    // Initialize text
    if (!this.grow) {
      this.currentList = this.randomize(
        this.currentList,
        0,
        this.currentList.length
      );
      this.updateText(this.currentList.join(''));
    }

    await this.delay(this.initialDelay);

    if (this.grow) {
      await this.growList(this.currentList, this.growInterval);
    }

    // Convert text to target value
    await this.convertList(
      this.currentList,
      this.targetText,
      this.convertInterval
    );
  }

  updateText(value: string) {
    this.slideElement.nativeElement.innerText = value;
  }

  /**
   * Grow a list of strings with random numbers from left to right.
   * @param list The list initialized to the appropriate length to grow.
   */
  async growList(list: Array<string>, interval: number) {
    var lo = 0;
    var hi = 0;
    while (hi < list.length) {
      this.randomize(list, lo, hi);
      this.updateText(list.join(''));
      await this.delay(interval);
      hi++;
    }
    this.currentList = list;
  }

  /**
   * Convert random numbers to the target string.
   * @param list Current state of the text rendered in the HTML.
   * @param target Target string to transform the characters in the list.
   * @param interval Delay before converting the next character.
   */
  async convertList(list: Array<string>, target: string, interval: number) {
    var lo = 0;
    var hi = 0;
    while (lo < list.length) {
      this.randomize(list, lo, hi);
      list[lo] = target[lo];
      this.updateText(list.join(''));
      await this.delay(interval);
      lo++;
    }
  }

  randomize(list: Array<string>, lo: number, hi: number): Array<string> {
    for (var i = lo; i < hi; i++) {
      list[i] = `${this.generateRandomInteger(2)}`;
    }
    return list;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  generateRandomInteger(max: number): number {
    return Math.floor(Math.random() * Math.floor(max));
  }
}
