<div id="footer">
  <div class="container">
    <div class="container-text">
      <div class="mat-display-3">
        POWERED BY ANGULAR AND FIREBASE
        <span class="spacer"></span>
        V1.0
      </div>
    </div>
  </div>
</div>
