<div id="header">
  <mat-toolbar color="primary">
    <span
      ><a class="mat-headline" (click)="scrollToSection('section-landing')"
        >JOSH MATSUSHIMA</a
      ></span
    >
    <div class="spacer"></div>
    <!-- Navigation For Mobile -->
    <div *ngIf="isHandset$ | async; else elseBlock">
      <button
        id="nav-dropdown-btn"
        mat-icon-button
        aria-label="Menu Dropdown Button"
        (click)="toggleDropdownMenu()"
      >
        <mat-icon>menu</mat-icon>
      </button>
      <div *ngIf="displayDropdown">
        <mat-nav-list class="vertical-menu">
          <a
            display-4
            class="nav-item"
            mat-list-item
            (click)="scrollToSection('section-about'); toggleDropdownMenu()"
            >ABOUT</a
          >
          <a
            class="nav-item"
            mat-list-item
            (click)="scrollToSection('section-portfolio'); toggleDropdownMenu()"
            >PORTFOLIO</a
          >
          <a
            class="nav-item"
            mat-list-item
            (click)="scrollToSection('section-contact'); toggleDropdownMenu()"
            >CONTACT</a
          >
        </mat-nav-list>
      </div>
    </div>
    <!-- Navigation For Desktop -->
    <ng-template #elseBlock>
      <div>
        <mat-nav-list class="horizontal-menu">
          <a
            class="nav-item"
            mat-list-item
            (click)="scrollToSection('section-about')"
            >ABOUT</a
          >
          <a
            class="nav-item"
            mat-list-item
            (click)="scrollToSection('section-portfolio')"
            >PORTFOLIO</a
          >
          <a
            class="nav-item"
            mat-list-item
            (click)="scrollToSection('section-contact')"
            >CONTACT</a
          >
        </mat-nav-list>
      </div>
    </ng-template>
  </mat-toolbar>
</div>
