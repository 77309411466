import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * If the device is a handset or not.
   */
  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .pipe(map((result) => result.matches));

  /**
   * Whether or not the dropdown menu should be displayed.
   */
  public displayDropdown: boolean = false;

  /**
   * Hide the dropdown menu when the device changes from handset to desktop.
   */
  private handleResize: Subscription;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private viewportScroller: ViewportScroller
  ) {}

  ngOnInit() {
    this.handleResize = this.isHandset$.subscribe((isHandset) => {
      if (!isHandset && this.displayDropdown) {
        this.displayDropdown = false;
      }
    });
  }

  ngOnDestroy() {
    this.handleResize.unsubscribe();
  }

  scrollToSection(id: string) {
    this.viewportScroller.scrollToAnchor(id);
  }

  toggleDropdownMenu() {
    this.displayDropdown = !this.displayDropdown;
    console.log(this.displayDropdown);
  }
}
