import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';

import { PortfolioService } from './services/portfolio.service';

@NgModule({
  // Make available to all subcomponents within this module
  imports: [CommonModule, SharedModule],
  // Make available to other modules that import this module
  exports: [HeaderComponent, FooterComponent],
  // Declare that a component is part of the module.
  declarations: [HeaderComponent, FooterComponent],
  providers: [PortfolioService],
})
export class CoreModule {}
