import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ViewportScroller } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import { auditTime, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-slide-in',
  templateUrl: './slide-in.component.html',
  styleUrls: ['./slide-in.component.scss'],
  animations: [
    trigger('slide', [
      state('init-l', style({ opacity: 0, transform: 'translateX(200px)' })),
      state('init-r', style({ opacity: 0, transform: 'translateX(-200px)' })),
      state('visible', style({ opacity: 1, transform: 'translateX(0)' })),
      transition('init-l => visible', [animate('{{speed}}s ease-in')]),
      transition('init-r => visible', [animate('{{speed}}s ease-in')]),
    ]),
  ],
})
export class SlideInComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('slideElement')
  public slideElement: ElementRef<HTMLElement>;

  @Input()
  public slideElementOverride: HTMLElement;

  @Input()
  public direction: 'left' | 'right' = 'right';

  @Input()
  public offsetY: number = 0;

  @Input()
  public animSpeed: number = 0.5;

  // Whether or not the component has appeared in the viewport.
  // This allows animations to be played only once.
  private viewed: boolean = false;

  // Element position
  private elementPosY: [number, number] = [-1, -1];

  // Screen bounds
  private displayY: [number, number] = [-1, -1];

  private subscriptionScroll: Subscription;

  private subscriptionResize: Subscription;

  constructor(private viewportScroller: ViewportScroller) {}

  ngOnInit() {
    // Setup subscription for mouse scroll event
    const scroll$ = fromEvent(document, 'scroll');
    this.subscriptionScroll = scroll$
      .pipe(
        takeWhile(() => !this.viewed),
        auditTime(100)
      )
      .subscribe(() => {
        this.updateBoundsY();
        this.checkInViewport();
      });

    // Setup subscription for resize event
    const resize$ = fromEvent(document, 'resize');
    this.subscriptionResize = resize$
      .pipe(
        takeWhile(() => !this.viewed),
        auditTime(100)
      )
      .subscribe(() => {
        this.updatePositionY();
        this.checkInViewport();
      });
  }

  ngOnDestroy() {
    this.subscriptionScroll.unsubscribe();
    this.subscriptionResize.unsubscribe();
  }

  ngAfterViewInit() {
    this.updatePositionY();
    this.updateBoundsY();
    // Workaround for ExpressionChangedAfterItHasBeenCheckedError bug in Angular
    setTimeout(() => this.checkInViewport(), 5);
  }

  public get state(): string {
    return this.viewed
      ? 'visible'
      : this.direction == 'left'
      ? 'init-l'
      : 'init-r';
  }

  private updatePositionY() {
    const element = this.slideElementOverride
      ? this.slideElementOverride
      : this.slideElement.nativeElement;
    this.elementPosY[0] = element.offsetTop;
    this.elementPosY[1] = element.offsetTop + element.offsetHeight;
  }

  private updateBoundsY() {
    this.displayY[0] = this.viewportScroller.getScrollPosition()[1];
    this.displayY[1] = this.displayY[0] + window.innerHeight;
  }

  private checkInViewport() {
    if (this.viewed) return;
    // Determine if the content is currently displayed on the screen
    // If it is, then animate!
    const bottomInView =
      this.elementPosY[0] <= this.displayY[0] &&
      this.elementPosY[1] > this.displayY[0];
    const topInView =
      this.elementPosY[0] + this.offsetY < this.displayY[1] &&
      this.elementPosY[1] > this.displayY[1];
    const centerInView =
      this.elementPosY[0] >= this.displayY[0] &&
      this.elementPosY[1] <= this.displayY[1];
    if (bottomInView || topInView || centerInView) {
      this.viewed = true;
    }
  }
}
