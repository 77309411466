import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Project } from 'src/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  constructor(private db: AngularFirestore) {}

  getAllProjects(): Observable<Project[]> {
    return this.db
      .collection<Project>('projects', (ref) => {
        return ref.orderBy('order');
      })
      .valueChanges();
  }

  getVisibleProjects(): Observable<Project[]> {
    return this.db
      .collection<Project>('projects', (ref) => {
        return ref.where('hidden', '==', false).orderBy('order');
      })
      .valueChanges();
  }
}
